<template>
  <div class="index">
    <SliderMain />

    <div v-if="!isKids">
      <SliderFilms v-if="showBlocks['1']" :payload="{ title: 'Рекомендуем', category: 1, link: '/film?sort=rating' }" class="related recommend" />
      <div v-else data-block-id="1" class="related" />

      <SliderTop10 v-if="showBlocks['top10']" class="related top10" />
      <div v-else data-block-id="top10" class="related top10" />

      <SliderFilms v-if="showBlocks['2']" class="related" :payload="{ title: 'Что посмотреть сегодня', category: 2, link: `/film?year=${new Date().getFullYear()}` }" />
      <div v-else data-block-id="2" class="related" />

      <SliderPremiers v-if="showBlocks['15']" class="related premieres" />
      <div v-else data-block-id="15" class="related premieres" />

      <div class="about-site">
        <div>
          <img :data-src="'/static_files/seo_bg_desk.png'" class="lazyload" alt="Онлайн-кинотеатр KIOFF">
        </div>
        <div class="description">
          <h1>
            Онлайн-кинотеатр KIOFF с хорошим качеством видео
          </h1>
          <div class="text">
            <p>
              KIOFF - это онлайн-кинотеатр, который предлагает своим пользователям широкий выбор фильмов и сериалов в высоком качестве. Сайт работает на различных устройствах, что позволяет смотреть любимые фильмы и сериалы в любом месте и в любое время.
            </p>
            <p>
              Одним из главных преимуществ KIOFF является высокое качество видео. Все фильмы и сериалы доступны в HD-качестве, что позволяет наслаждаться просмотром без каких-либо проблем с изображением. Кроме того, сайт предлагает широкий выбор популярных фильмов, таких как "Мстители", "Звездные войны", "Гарри Поттер" и многие другие.
            </p>
            <button v-if="!showFullText" @click="showFullText = !showFullText">
              Показать еще <i class="arrow" />
            </button>
            <div v-show="showFullText">
              <p>
                Для любителей сериалов KIOFF предлагает бесплатный просмотр популярных русских и зарубежных сериалов. Среди них можно найти такие хиты, как "Игра престолов", "Друзья", "Во все тяжкие" и многие другие. Благодаря удобному интерфейсу сайта, можно легко найти нужный сериал и начать просмотр в любое время.
              </p>
              <p>
                KIOFF работает на различных устройствах, включая компьютеры, планшеты и смартфоны. Это позволяет смотреть любимые фильмы и сериалы в любом месте и в любое время. Кроме того, сайт имеет удобный поиск, который позволяет быстро найти нужный фильм или сериал.
              </p>
              <p>
                KIOFF - это отличный выбор для тех, кто ищет онлайн-кинотеатр с высоким качеством видео и широким выбором фильмов и сериалов. Благодаря бесплатному просмотру сериалов и удобному интерфейсу сайта, пользователи могут наслаждаться просмотром любимых фильмов и сериалов в любое время и в любом месте.
              </p>
            </div>
          </div>
        </div>
      </div>

      <SliderFilms v-if="showBlocks['3']" class="related" :payload="{ title: 'Комедии', category: 3, link: `/film?genre=комедия` }" />
      <div v-else data-block-id="3" class="related" />

      <SliderFilms v-if="showBlocks['4']" class="related" :payload="{ title: 'Громкие хиты', category: 4, link: `/film?sort=rating` }" />
      <div v-else data-block-id="4" class="related" />

      <SliderFilms v-if="showBlocks['5']" class="related" :payload="{ title: 'Новое на KIOFF', category: 5, link: `/film?sort=date` }" />
      <div v-else data-block-id="5" class="related" />

      <SliderFilms v-if="showBlocks['6']" class="related" :payload="{ title: 'Эксклюзив. Европейские сериалы', category: 6, link: `/film?type=serials` }" />
      <div v-else data-block-id="6" class="related" />

      <SliderFilms v-if="showBlocks['7']" class="related" :payload="{ title: 'Фильмы про любовь', category: 7, link: `/film?genre=мелодрама` }" />
      <div v-else data-block-id="7" class="related" />

      <SliderFilms v-if="showBlocks['16']" class="related" :payload="{ title: '20 минут на кино', category: 16, link: `/film?genre=короткометражка` }" />
      <div v-else data-block-id="16" class="related" />

      <SliderFilms v-if="showBlocks['8']" class="related" :payload="{ title: 'Популярные мультфильмы', category: 12, link: `/film?type=films&sort=rating` }" />
      <div v-else data-block-id="8" class="related" />

      <SliderFilms v-if="showBlocks['9']" class="related" :payload="{ title: 'Советское время', category: 8, link: `/film?country=СССР` }" />
      <div v-else data-block-id="9" class="related" />

      <SliderFilms v-if="showBlocks['10']" class="related" :payload="{ title: 'Российское документальное кино', category: 9, link: `/film?country=Россия&genre=документальный` }" />
      <div v-else data-block-id="10" class="related" />

      <SliderFilms v-if="showBlocks['11']" class="related" :payload="{ title: 'Детективы и триллеры', category: 10, link: `/film?genre=детектив` }" />
      <div v-else data-block-id="11" class="related" />

      <SliderFilms v-if="showBlocks['12']" class="related" :payload="{ title: 'Сериалы с высоким рейтингом', category: 13, link: `/film?type=serials&sort=rating` }" />
      <div v-else data-block-id="12" class="related" />

      <SliderFilms v-if="showBlocks['13']" class="related" :payload="{ title: 'Музыка на KIOFF', category: 11, link: `/film?genre=музыка` }" />
      <div v-else data-block-id="13" class="related" />

      <SliderFilms v-if="showBlocks['14']" class="related" :payload="{ title: 'Наше кино', category: 14, link: `/film?type=film&country=Россия` }" />
      <div v-else data-block-id="14" class="related" />

      <SliderFilms v-if="showBlocks['17']" class="related" :payload="{ title: 'Добрые и смешные сериалы', category: 17, link: `/film?type=serials&genre=семейный` }" />
      <div v-else data-block-id="17" class="related" />

      <SliderFilms v-if="showBlocks['18']" class="related" :payload="{ title: 'Новинки для детей', category: 18, link: `/film?genre=детский&sort=date` }" />
      <div v-else data-block-id="18" class="related" />
    </div>
    <div v-else>
      <SliderFilms :payload="{ title: 'Популярное', category: '01', link: `/film?genre=мультфильм&sort=rating` }" style="position:relative;margin-top:-82px;z-index:1;" class="related" />
      <!-- <div v-else data-block-id="1" class="related" /> -->

      <SliderFilms v-if="showBlocks['02']" :payload="{ title: 'Новинки для детей', category: '02', link: `/film?genre=мультфильм&sort=date` }" class="related" />
      <div v-else data-block-id="02" class="related" />

      <SliderFilms v-if="showBlocks['03']" :payload="{ title: 'Фильмы', category: '03', link: `/film?type=films&genre=мультфильм` }" class="related" />
      <div v-else data-block-id="03" class="related" />

      <SliderFilms v-if="showBlocks['04']" :payload="{ title: 'Музыка', category: '04', link: `/film?genre=мультфильм` }" class="related" />
      <div v-else data-block-id="04" class="related" />

      <SliderFilms v-if="showBlocks['05']" :payload="{ title: 'Малышам', category: '05', link: `/film?genre=детский` }" class="related" />
      <div v-else data-block-id="05" class="related" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    SliderMain: () => import('~/components/SliderMain.vue'),
    SliderFilms: () => import('~/components/SliderFilms.vue'),
    SliderPremiers: () => import('~/components/SliderPremiers.vue'),
    SliderTop10: () => import('~/components/SliderTop10.vue')
  },
  computed: mapState(['isKids']),
  asyncData({ query, redirect }) {
    if (Object.keys(query).length) return redirect('/')
    return {
      showFullText: false,
      showBlocks: {
        '1': false, '2': false, '3': false, '4': false, '5': false, '6': false, '7': false,
        '8': false, '9': false, '10': false, '11': false, '12': false, '13': false,
        '14': false, '15': false, '16': false, '17': false, '18': false, '02': false,
        '03': false, '04': false, '05': false, 'top10': false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showBlock)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showBlock)
  },
  methods: {
    showBlock() {
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.related')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockId = block.dataset.blockId
        if (visible > -doc && !this.showBlocks[blockId]) {
          this.showBlocks[blockId] = true
        }
      })
    }
  }
}
</script>

<style lang="stylus">
.index
  .about-site
    position: relative
    display: grid
    grid-template-columns: 40vw auto
    grid-gap: 60px
    margin: 56px 0
    padding: 56px 40px
    img
      width 100%
    .description
      h1
        margin-bottom: 20px
        font-size: 2rem
      .text
        p
          margin: 20px 0
          font-size: 1.8rem
          line-height: 2.8rem
        button
          display: inline-block
          margin-right: 10px
          padding: 0
          font-size: 1.6rem
          color: #f2f2f2
          background-color: #1d1f23
          border: none
          cursor: pointer
        i
          display: inline-block
          width: 16px
          height: 16px
          background-image: url('/static_files/icons/arrow-right-dark.svg')
          background-size: 16px
          transform: rotateZ(90deg)
          vertical-align: middle
</style>
